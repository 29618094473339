<template>
  <KpiDireksiDekomForm mode="Tambah" module="KPI Direksi Dekom"> </KpiDireksiDekomForm>
</template>

<script>
import KpiDireksiDekomForm from './form';

const KpiDireksiDekomAdd = {
  name: 'KpiDireksiDekomAdd',
  components: { KpiDireksiDekomForm },
};

export default KpiDireksiDekomAdd;
</script>
